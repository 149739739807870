@import "@scrippsproduct/entertainment-sites-css/src/header.scss";

.header-divider {
    border-color: var(--mono-color-100);
}

header {
    .main-logo {
        transition: scale 0.225s ease-out, filter 0.225s ease-out, translate 0.225s ease-out;

        &:hover {
            translate: 0 calc(var(--ira-baseline-adjustment) - 0.1rem);
        }
    }
}

header-menu-wrapper {
    margin-block-start: 0.75rem;
}

.main-navigation {
    margin: 0 2rem 0 auto;
}

.main-navigation__nav-link:before {
    bottom: unset;
    top: -0.1em;
}

.mobile-nav { height: 5rem; }

.drawer-navigation__nav-bar {
    padding-block-start: 1.1rem;
}

.mobile-nav {       
    background-image: var(--body-background-url);
    background-attachment: var(--body-background-attachment);
    background-position: var(--body-background-position);
    background-repeat: var(--body-background-repeat);
    background-size: var(--body-background-size);

    &.mobile-nav--shadow {
        background-image: var(--body-background-url);
    }
}

.drawer-navigation__primary-navigation {
    background: linear-gradient(to bottom, var(--theme-color-100) 0%, var(--theme-color-500) 100%) !important;
}