:root {
    /* theme colors */
    --theme-color-100: hsl(13, 87%, 54%);
    --theme-color-200: hsl(13, 87%, 54%);
    --theme-color-300: hsl(13, 87%, 54%);
    --theme-color-400: hsl(13, 87%, 54%);
    --theme-color-500: hsl(26, 87%, 55%);
    /* monotones */
    --mono-color-100: #fff;
    --mono-color-200: hsla(0, 0%, 0%, 0.169);
    --mono-color-300: hsl(0, 0%, 40%);
    --mono-color-400: #3d3d3d;
    --mono-color-500: hsl(0, 0%, 40%);
    --mono-color-900: hsl(0, 0%, 0%);
    /* form colors */
    --form-valid-color: hsl(150, 55%, 38%);
    --form-invalid-color: hsl(0, 51%, 50%);
    /* gradients */
    --body-gradient: linear-gradient(to bottom, transparent 0%, transparent 100%);
    --footer-gradient: linear-gradient(180deg, transparent, transparent);
    --menu-gradient: linear-gradient(90deg, hsl(39, 100%, 41%) 0%, hsl(30, 82%, 58%) 50%, hsl(28, 82%, 55%) 90%);
    --on-now-gradient: linear-gradient(to bottom, var(--theme-color-100) 0%, var(--theme-color-100) 100%); //linear-gradient(to bottom, var(--form-valid-color) 0%, hsl(150, 54%, 27%) 100%);
    /* misc */
    --cta-button-color: var(--theme-color-300);
    --base-anchor-color: var(--theme-color-100);    
    --on-now-text-color: var(--theme-color-100);
    --on-now-button-color: var(--theme-color-100);
    --main-font-family: 'Montserrat', sans-serif;
    --body-background-url: url(https://ewscripps.brightspotcdn.com/5b/36/d78047ce45feaa1541be32ca4b19/image-2x.png);
    --body-background-blend-mode: normal;
    --body-background-repeat: no-repeat;
    --body-background-size: 100% calc(100% + 5px);
    --body-background-position: top center;
    --body-background-attachment: fixed;
    --paragraph-font-family: 'Open Sans', sans-serif;
    --anchor-color: var(--theme-color-100);
    --anchor-hover-color: var(--theme-color-300);
    --headers-color: var(--mono-color-900);
    --error-handler-background-color:  var(--mono-color-100);
    --error-handler-outline-color: var(--theme-color-300);
    --modals-close-btn-color: var(--mono-color-100);
    --modals-close-btn-outline-color: var(--theme-color-300);
    --inline-anchor-color: var(--theme-color-400);
    --tabbed-content-border-color: #B6BCBE;

    @media screen and (width < 37.5rem) {
        --body-background-size: 900px 100%;
    }
}