
@import "@scrippsproduct/entertainment-sites-css/src/page-not-found.scss";

.page-not-found__link {

    > a {
        color: var(--theme-color-100);

        &:hover {
            color: var(--theme-color-300);
        }
    }
}
