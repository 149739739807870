@import 'Settings';
@import "@scrippsproduct/entertainment-sites-css/src/base.scss";

.content-constrainer--lead-container {
    padding-block-start: 1.3rem;
}

header {
    padding-block-start: 2rem;

    @media screen and (max-width: 60rem) {
        padding-block-start: 4rem;
    }
}

hr {
    border-color: var(--theme-color-100);
}

.main-content.info-page {
    .h1:first-child {
        margin-block-start: 0
    }
}

.page-content-subheader {
    margin-block-end: 2.5rem;
}

@media screen and (max-width: 60rem) {
    site-wrapper {
        padding-block-start: 5rem;
    }
}

